<template>
  <div>
    <v-container fluid>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="blue accent-4"
      ></v-progress-linear>
      <v-snackbar v-model="snackbar" :color="snackbar_color" rounded="pill" top>
        {{ text }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" icon v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-row justify="center" align-sm="center" class="mt-2">
        <v-col sm cols="12" align-self="center" class="d-flex justify-center">
          <v-img
            alt="100 Miles Logo"
            class="mr-2 shrink"
            contain
            src="../../assets/login-page-logo.png"
            transition="scale-transition"
            max-height="150"
            max-width="150"
          />
        </v-col>
        <v-col
          sm
          cols="12"
          align-self="center"
          class="
            d-flex
            justify-center
            rounded-t-xl
            grey
            lighten-5
            flex-column
            pa-6
          "
        >
          <span class="primary--text mb-2 title">SIGN IN</span>
          <v-divider light></v-divider>
          <v-form
            v-on:submit.prevent="login"
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              v-model="user.email"
              :rules="emailRules"
              label="E-mail"
              prepend-inner-icon="fas fa-envelope"
              class="mb-2 mt-4"
              rounded
              outlined
              color="primary"
            />
            <v-text-field
              v-model="user.password"
              :rules="passwordRules"
              label="Password"
              :counter="8"
              :type="ShowPassword ? 'text' : 'password'"
              prepend-inner-icon="fas fa-lock"
              :append-icon="ShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="ShowPassword = !ShowPassword"
              class="mb-2"
              rounded
              outlined
            />
            <div class="float-right mb-2">
              <router-link to="/auth/forgotpassword" class="routerlink caption"
                >Forgot Password?</router-link
              >
            </div>

            <!--<v-checkbox
                v-model="user.remember_me"
                label="Remember Me"
                color="info"
                value=""
                class="mb-4"
              />-->

            <v-btn
              color="info"
              type="submit"
              rounded
              block
              @click="validate"
              :disabled="!valid"
            >
              Sign In
            </v-btn>
          </v-form>
        </v-col>
        <v-col
          sm
          cols="12"
          align-self="center"
          class="
            d-flex
            justify-center
            align-center
            rounded-b-xl
            grey
            lighten-5
            flex-column
          "
        >
          <div>
            Don't have an account ?
            <router-link to="/auth/register" class="routerlink"
              >Create Account</router-link
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Authentication from "../../api/Authentication";
export default {
  name: "login",
  data() {
    return {
      valid: true,
      ShowPassword: false,
      loading: false,
      snackbar: false,
      snackbar_color: "",
      text: "",
      user: {
        email: "",
        password: "",
        remember_me: false,
      },

      errors: [],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid.",
      ],

      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => v.length >= 8 || "password at least 8 characters.",
      ],
    };
  },
  methods: {
    ...mapActions(["login"]),
    validate() {
      this.$refs.form.validate();
    },
    login: function () {
      this.loading = true;
      Authentication.login(this.user)
        .then((response) => {
          //console.log(response.data.access_token);
          if (response.data.access_token !== null) {
            this.loading = false;
            this.$store.commit("SET_TOKEN", response.data.access_token);
            if (response.data.role == 1) {
              localStorage.setItem("token", response.data.access_token);
              localStorage.setItem("rlid", response.data.role);
              localStorage.setItem("vrid", response.data.vrid);
              this.$router.replace("/admin/dashboard");
            } else {
              localStorage.setItem("token", response.data.access_token);
              localStorage.setItem("rlid", response.data.role);
              localStorage.setItem("vrid", response.data.vrid);
              this.$router.replace("/user/home");
            }
          } else {
            this.loading = false;
            this.$router.push("/Auth/login");
          }
          // commit('SET_TOKEN',response.data.access_token)
        })
        .catch((err) => {
          this.loading = false;
          this.snackbar_color = "error";
          this.text = err.response.data.message;
          this.snackbar = true;
        });
    },
  },
};
</script>
<style scoped></style>
